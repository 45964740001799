<template>
    <div>
        <chart
            v-if="localConfig"
            ref="launchpad-incident-trendline"
            :config="localConfig"
            :menu="false"
            @chartClicked="incidentClicked"
            data-test="launchpad-incidents-trendline"
        />
        <div v-else>
            <div v-if="!ready">
                <fe-spinner class="spinner"/>
            </div>
            <div v-else style="align-content: center; height: 280px; text-align: center; color: #495B8F;">
                <v-icon style="font-size: 32px; color: #495B8F;" class="mb-5 incidents-unavailable-icon">fal fa-exclamation-circle</v-icon>
                <div style="font-weight: 700">Data temporarily unavailable</div>
                <div>Refresh the page or try again later.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Chart from "@/components/charts/Chart"
import { trendline_config } from './incident_chart_configs'

export default {
    name: 'IncidentsTrendline',

    components: {
        Chart,
    },

    computed: {
        ...mapState('global', ['currentYear', 'defaultSchool']),
    },

    data() {
        return {
            localConfig: null,
            ready: false,
            categories: [],
            yAxis: {
                max: undefined, min: undefined
            },
            charts: null,
        }
    },

    mounted() {
        this.loadData()
    },

    methods: {
        incidentClicked(event, point) {
            let start = point.month + '/1/' + point.year
            let end = point.month + '/' + point.total_days + '/' + point.year
            let chartParams = {
                start_date: start,
                end_date: end,
                school_year_id: [{name: point.series.name}]
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id
            let chartFilters = []
            let params = {chartParams, chartFilters}
            this.$router.replace({ name: 'Incident Charting', params })
        },
        loadData() {
            this.ready = false
            let chartParams = {}
            chartParams.school_year_id = [this.currentYear.id, this.currentYear.id - 1]
            this.charts = []
            const chartType = ['incident_month']
            const primaryOnly = window.localStorage.getItem('ec-incidents-primary-only')
            const showPrimaryOnly = primaryOnly !== null ? primaryOnly : '1'
            const params = {
                ...chartParams,
                chart_types: [chartType],
                include_secondary_behaviors_responses: showPrimaryOnly === '1' ? 0 : 1
            }
            this.$axios.get(this.$models.getUrl('studentIncidentChart', 'read') + '&' + this.$objectToParams(params))
                .then(response => {
                    const data =  this.$ecResponse(response, 'incidents')
                    data?.charts.forEach((chart, i) => {
                        let categories = chart.fields || []
                        this.charts.push({
                            id: i,
                            title: chart.title,
                            chartType: chart.chart_type,
                            x_field: chart.x_field,
                            categories: categories,
                            config: chart,
                            baseParams: chartParams
                        })
                    })
                })
                .finally(() => {
                    this.buildSeries()
                })
        },
        buildSeries() {
            let series = []

            let symbols = ['square', 'circle']
            let twoToOneRatioColors = ['#2B3963', '#2B87FF']

            this.categories = this.charts[0].categories

            let months = ['January','February','March','April','May','June','July','August','September','October','November','December']

            if (this.charts && this.charts[0].config.data) {
                series.push({
                    name: '2023-2024',
                    data: [],
                    color: twoToOneRatioColors[0],
                    marker: {
                        symbol: symbols[0],
                        radius: 4,
                    }
                })
                series.push({
                    name: '2024-2025',
                    data: [],
                    color: twoToOneRatioColors[1],
                    marker: {
                        symbol: symbols[1],
                        radius: 4,
                    },
                })

                series.forEach(serie => {
                    if (this.categories.length > 0) {
                        this.categories.forEach(cat => {
                            let rec = this.charts[0].config.data.find(data => data['incident_month_name'] === cat && data['school_year_name'] === serie.name)
                            let rate = rec ? rec.total_count : 0
                            let monthNum = months.findIndex(x => x === cat) + 1
                            let daysInMonth = rec ? rec.incident_days_in_month : null
                            let correspondingYr = null
                            if(monthNum) {
                                let firstYr = serie.name.split('-')[0]
                                let secondYr = serie.name.split('-')[1]
                                if(monthNum > 7) correspondingYr = firstYr
                                else correspondingYr = secondYr
                            }
                            serie.data.push({y: rate, month: monthNum, year: correspondingYr, total_days: daysInMonth}) //if pushing multiple values, highcharts uses 'y' as point to plot

                            if (this.yAxis.min === undefined || rate < this.yAxis.min) this.yAxis.min = Math.floor(rate)
                            if (this.yAxis.max === undefined || rate > this.yAxis.max) this.yAxis.max = rate
                        })
                    }
                })

            }
            this.localConfig = this.buildChart(series)
            this.ready = true
        },
        buildChart(series) {
            let config = trendline_config
            config.series = series
            config.xAxis.categories = this.categories

            return config
        }
    }
}
</script>

<style lang="scss" scoped>
.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
}
</style>
