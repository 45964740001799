import * as AdvancedFiltersUtil from '@/components/common/util/AdvancedFilters'

export function UUIDv4() {
    let firstPart = (Math.random() * 46656) | 0
    let secondPart = (Math.random() * 46656) | 0
    let thirdPart = (Math.random() * 46656) | 0
    firstPart = ("000" + firstPart.toString(36)).slice(-3)
    secondPart = ("000" + secondPart.toString(36)).slice(-3)
    thirdPart = ("000" + thirdPart.toString(36)).slice(-3)
    return firstPart + secondPart + thirdPart
}

export function buildDemographicsObject(advancedFilterParams) {
    let demographics = {}

    if (advancedFilterParams) {
        for (let key of Object.keys(advancedFilterParams).filter(key => `${key}`.substr(0, 5) == 'demo_')) {
            demographics = { ...demographics, [key]: advancedFilterParams[key] }
        }
    }

    return demographics
}

export function buildPopulationSearchParams(population) {
    let params = {
        ...population,
        ...population.advancedFilters,
        ...population.advancedFilters?.demographics
    }

    if (params.group_by && (typeof params.group_by == 'object')) {
        params = {
            ...params,
            ...AdvancedFiltersUtil.getGroupBy(params.group_by), // this will overwrite group_by with an array
        }
    }

    // Periods don't have ids - they're raw text.  To functionally support an
    // "All Periods" type of option, we should intercept this property and
    // remove it (i.e. not supplying a period means all periods will be considered)
    if (params.period && params.period instanceof Array && params.period.includes('All Periods')) {
        delete params.period
    } else if (params.period && params.period instanceof Array) {
        params.period = params.period.join('+')
    }

    // Delete some properties that get carried along that
    // have no relevance to the BE queries
    delete params.advancedFilters
    delete params.demographics
    delete params.saved_search_group_id
    delete params.saved_search_group_name
    delete params.selected

    return params
}

export function unpackSearchParams(arr) {
    let searchParams = {}
    for (let itm of arr) {
        searchParams = { ...searchParams, ...itm }
    }

    searchParams = {
        ...searchParams,
        ...AdvancedFiltersUtil.getGroupBy()
    }

    // A couple of intervention fields can have inconsistency between
    // BE/FE.  We always want to send the _id variant
    searchParams.intervention_level_id = searchParams.intervention_level_id || searchParams.intervention_level || undefined
    searchParams.intervention_exit_status_id = searchParams.intervention_exit_status_id || searchParams.intervention_exit_status || undefined
    delete searchParams.intervention_level
    delete searchParams.intervention_exit_status

    let unpacked = {
        searchParams: searchParams,
        populationParams: arr.find(itm => itm.saved_search_group_name === 'population'),
        advancedFilterParams: arr.find(itm => itm.saved_search_group_name === 'advanced-filters'),
        dataParams: arr.find(itm => itm.saved_search_group_name === 'data'),
        incidentParams: arr.find(itm => itm.saved_search_group_name === 'incident'),
        assessmentParams: arr.find(itm => itm.saved_search_group_name === 'assessment'),
        assessmentParams1: arr.find(itm => itm.saved_search_group_name === 'assessment1'),
        assessmentParams2: arr.find(itm => itm.saved_search_group_name === 'assessment2'),
    }

    if (unpacked.searchParams) {
        let periods = unpacked.searchParams.period
        if (!(periods instanceof Array)) {
            periods = [periods]
        }

        unpacked.searchParams = {
            ...unpacked.searchParams,
            // booleans must be converted to tinyint for backend
            incl_hidden_data_point: unpacked.searchParams.incl_hidden_data_point ? 1 : 0,
            // Periods don't have idea, so "All Periods" is a raw value and should be stripped out (i.e. not supplying a period means all periods will be considered)
            period: periods.includes('All Periods') ? undefined : periods.join('+'),
        }
    }

    if (unpacked.advancedFilterParams) {
        unpacked.advancedFilterParams = {
            ...unpacked.advancedFilterParams,
            // Must use a workaround for inconsistency between FE/BE naming for intervention fields
            intervention_level: unpacked.advancedFilterParams.intervention_level_id || undefined,
            intervention_exit_status: unpacked.advancedFilterParams.intervention_exit_status_id || undefined,
        }
    }

    // If a legacy saved search does not carry the dataParams,
    // ensure a default of school year id value based on the selected cohort year
    if (!unpacked.dataParams) {
        unpacked.dataParams = {
            school_year_id: [unpacked.populationParams.cohort_school_year_id || unpacked.populationParams.school_year_id]
        }
    }

    return unpacked
}

export function doBenchmarkChart(cmp, opts, scope, savedSearch, state) {
    let arr

    let includeIncidents, includeAttendance

    if (!savedSearch) {
        arr = [{ ...opts.population, saved_search_group_name: 'population' }]
        if (opts.population?.advancedFilters) {
            let obj = ({ ...opts.population.advancedFilters, ...opts.population.advancedFilters?.demographics, saved_search_group_name: 'advanced-filters'})
            obj = { ...obj, ...AdvancedFiltersUtil.getGroupBy(obj.group_by) }
            Object.keys(obj).forEach(x => {
                if (x.substring(0, 5) === 'demo_' && !obj.demographics.hasOwnProperty(x)) {
                    delete obj[x] // remove any advanced demos that were moved to top level, then removed
                }
            })
            delete obj.demographics // all advanced demos get moved to top level to be saved as flat saved search
            arr.push(obj)
        }
        if (opts.data) {
            arr.push({ ...opts.data, saved_search_group_name: 'data' })
        }
        if (opts.assessment) {
            arr.push({ ...opts.assessment, saved_search_group_name: 'assessment' })
        }
        if (opts.incident) {
            arr.push({ ...opts.incident, saved_search_group_name: 'incident' })
        }

        includeIncidents = !!opts.incident
        includeAttendance = !!opts.attendance
    } else {
        arr = opts

        includeAttendance = !!savedSearch.config_json?.include_attendance
        includeIncidents = !!savedSearch.config_json?.include_incidents
    }

    let savedSearchParamsByDomain = unpackSearchParams(arr)
    let { searchParams, populationParams, advancedFilterParams, dataParams, assessmentParams, incidentParams } = savedSearchParamsByDomain
    let searchPopulationParams = buildPopulationSearchParams({ ...populationParams, ...advancedFilterParams })

    incidentParams = {
        ...incidentParams,
        // All Class-scoped searches must limit to only active students
        student_active_flag: scope?.toLowerCase() == 'class' ? 1 : searchPopulationParams.student_active_flag,
    }

    let searchAttendanceParams = {
        ...searchPopulationParams,
        group_by: 'grade',
        chart_group_by: [],
        school_year_id: searchPopulationParams.cohort_school_year_id || searchPopulationParams.school_year_id,
        // All Class-scoped searches must limit to only active students
        student_active_flag: scope?.toLowerCase() == 'class' ? 1 : searchPopulationParams.student_active_flag,
    }
    delete searchAttendanceParams.chart_type

    let defaultWindowTitle = scope?.toLowerCase() == 'district' ? 'District Search' : scope?.toLowerCase() == 'grade' ? 'Grade Search' : scope?.toLowerCase() == 'class' ? 'Class Search' : null

    let windowUUID = UUIDv4()
    let windowId = cmp.$dockableWindow({
        name: [defaultWindowTitle || 'Assessment Reports', savedSearch?.name || state?.savedSearchName].filter(s => !!s).join(' - '),
        uuid: windowUUID,
        component: 'save-search-container',
        parentStyle: {
            overflow: 'hidden'
        },
        events: {
            deletedSavedSearch(id) {
                cmp.queries = cmp.queries.filter(itm => itm.id !== id)
            },
            close() {
                cmp.$store.commit('global/removeDockedWindow', windowId)
            },
        },
        attrs: {
            windowUUID: windowUUID,
            savedSearchParams: arr,
            savedSearchParamsByDomain: savedSearchParamsByDomain,
            includeAttendance: includeAttendance,
            component: 'charting',
            savedSearchType: 'BENCHMARK',
            configJSON: savedSearch?.config_json || {
                scope: scope,
                component: 'charting',
                include_attendance: includeAttendance,
                include_incidents: includeIncidents,
            },
            isSavedSearch: !!state?.savedSearchId,
            searchId: state?.savedSearchId,
            searchName: state?.savedSearchName,
            isDirty: !!state?.isDirty,
            attrs: {
                scope: scope,
                searchParams: { ...searchParams, isEmbeddedInDockableWindow: true },
                noAssessmentData: !assessmentParams,
                searchIncidentParams: { ...searchPopulationParams, school_year_id: dataParams.school_year_id, ...cmp.$filterNegativeIds(incidentParams || {}) },
                searchAttendanceParams: searchAttendanceParams,
                noIncidentData: !includeIncidents,
                noAttendanceData: !includeAttendance
            }
        }
    })
}

export function doDataWall(cmp, opts, title, scope, savedSearch, state) {
    let arr

    let includeIncidents, includeAttendance

    if (!savedSearch) {
        arr = [{ ...opts.population, saved_search_group_name: 'population' }]
        if (opts.population?.advancedFilters) {
            let obj = ({ ...opts.population?.advancedFilters, ...opts.population?.advancedFilters?.demographics, saved_search_group_name: 'advanced-filters'})
            obj = { ...obj, ...AdvancedFiltersUtil.getGroupBy(obj.group_by) }
            Object.keys(obj).forEach(x => {
                if (x.substring(0, 5) === 'demo_' && !obj.demographics.hasOwnProperty(x)) {
                    delete obj[x] // remove any advanced demos that were moved to top level, then removed
                }
            })
            delete obj.demographics // all advanced demos get moved to top level to be saved as flat saved search
            arr.push(obj)
        }
        if (opts.data) {
            arr.push({ ...opts.data, saved_search_group_name: 'data' })
        }
        if (opts.assessment) {
            arr.push({ ...opts.assessment, saved_search_group_name: 'assessment' })
        }
        if (opts.incident && Object.keys(cmp.$filterNegativeIds(opts.incident)).length > 0) {
            arr.push({ ...cmp.$filterNegativeIds(opts.incident), saved_search_group_name: 'incident' })
        }

        includeIncidents = !!opts.incident
        includeAttendance = !!opts.attendance
    } else {
        arr = opts

        includeAttendance = !!savedSearch.config_json?.include_attendance
        includeIncidents = !!savedSearch.config_json?.include_incidents
    }

    let savedSearchParamsByDomain = unpackSearchParams(arr)
    let { searchParams, populationParams, advancedFilterParams, dataParams } = savedSearchParamsByDomain
    let searchPopulationParams = buildPopulationSearchParams({ ...populationParams, ...advancedFilterParams })

    let dataWallParams = { ...searchParams, ...dataParams, include_attendance: includeAttendance ? 'on' : undefined }
    delete dataWallParams.group_by

    let windowUUID = UUIDv4()
    let windowId = cmp.$dockableWindow({
        name: [title, savedSearch?.name || state?.savedSearchName].filter(s => !!s).join(' - '),
        uuid: windowUUID,
        component: 'save-search-container',
        parentStyle: {
            overflow: 'hidden'
        },
        events: {
            deletedSavedSearch(id) {
                cmp.queries = cmp.queries.filter(itm => itm.id !== id)
            },
            close() {
                cmp.$store.commit('global/removeDockedWindow', windowId)
            },
        },
        attrs: {
            windowUUID: windowUUID,
            savedSearchParams: arr,
            savedSearchParamsByDomain: savedSearchParamsByDomain,
            includeAttendance: includeAttendance,
            component: 'data-wall',
            savedSearchType: 'BENCHMARK',
            configJSON: savedSearch?.config_json || {
                scope: scope,
                component: 'data-wall',
                include_attendance: includeAttendance,
                include_incidents: includeIncidents,
            },
            isSavedSearch: !!state?.savedSearchId,
            searchId: state?.savedSearchId,
            searchName: state?.savedSearchName,
            isDirty: !!state?.isDirty,
            attrs: {
                params: dataWallParams,
                scope: scope,
            }
        }
    })
}

export function doProgramEvaluation(cmp, opts, scope, savedSearch, state) {
    let arr

    if (!savedSearch) {
        arr = [{ ...opts.population, saved_search_group_name: 'population' }]
        if (opts.population?.advancedFilters) {
            let obj = ({ ...opts.population?.advancedFilters, ...opts.population?.advancedFilters?.demographics, saved_search_group_name: 'advanced-filters'})
            obj = { ...obj, ...AdvancedFiltersUtil.getGroupBy(obj.group_by) }
            Object.keys(obj).forEach(x => {
                if (x.substring(0, 5) === 'demo_' && !obj.demographics.hasOwnProperty(x)) {
                    delete obj[x] // remove any advanced demos that were moved to top level, then removed
                }
            })
            delete obj.demographics // all advanced demos get moved to top level to be saved as flat saved search
            arr.push(obj)
        }
        if (opts.assessment) {
            arr.push({ ...opts.assessment, saved_search_group_name: 'assessment' })
        }
    } else {
        arr = opts
    }

    let savedSearchParamsByDomain = unpackSearchParams(arr)
    let { searchParams, populationParams, advancedFilterParams, assessmentParams } = savedSearchParamsByDomain
    let searchPopulationParams = buildPopulationSearchParams({ ...populationParams, ...advancedFilterParams })

    let windowUUID = UUIDv4()
    let windowId = cmp.$dockableWindow({
        name: ['Program Evaluation', savedSearch?.name || state?.savedSearchName].filter(s => !!s).join(' - '),
        uuid: windowUUID,
        component: 'save-search-container',
        parentStyle: {
            overflow: 'hidden'
        },
        events: {
            deletedSavedSearch(id) {
                cmp.queries = cmp.queries.filter(itm => itm.id !== id)
            },
            close() {
                cmp.$store.commit('global/removeDockedWindow', windowId)
            },
        },
        attrs: {
            windowUUID: windowUUID,
            savedSearchParams: arr,
            savedSearchParamsByDomain: savedSearchParamsByDomain,
            component: 'program-evaluation',
            savedSearchType: 'BENCHMARK',
            configJSON: savedSearch?.config_json || {
                scope: scope,
                component: 'program-evaluation',
            },
            isSavedSearch: !!state?.savedSearchId,
            searchId: state?.savedSearchId,
            searchName: state?.savedSearchName,
            isDirty: !!state?.isDirty,
            attrs: {
                class: 'pa-5',
                searchParams: { ...searchPopulationParams, ...assessmentParams },
                showFilters: false,
            }
        }
    })
}

export async function doScatterPlot(cmp, opts, scope, savedSearch, state) {
    let arr
    let config_json

    if (!savedSearch) {
        config_json = opts.configJSON

        arr = [{ ...opts.population, saved_search_group_name: 'population' }]
        if (opts.population?.advancedFilters) {
            let obj = ({ ...opts.population?.advancedFilters, ...opts.population?.advancedFilters?.demographics, saved_search_group_name: 'advanced-filters'})
            obj = { ...obj, ...AdvancedFiltersUtil.getGroupBy(obj.group_by) }
            Object.keys(obj).forEach(x => {
                if (x.substring(0, 5) === 'demo_' && !obj.demographics.hasOwnProperty(x)) {
                    delete obj[x] // remove any advanced demos that were moved to top level, then removed
                }
            })
            delete obj.demographics // all advanced demos get moved to top level to be saved as flat saved search
            arr.push(obj)
        }
        if (opts.assessment1) {
            arr.push({ ...opts.assessment1, saved_search_group_name: 'assessment1' })
        }
        if (opts.assessment2) {
            arr.push({ ...opts.assessment2, saved_search_group_name: 'assessment2' })
        }
    } else {
        arr = opts
    }

    let savedSearchParamsByDomain = unpackSearchParams(arr)
    let { searchParams, populationParams, advancedFilterParams, assessmentParams1, assessmentParams2 } = savedSearchParamsByDomain
    let searchPopulationParams = buildPopulationSearchParams({ ...populationParams, ...advancedFilterParams })

    let { dataset1, dataset2 } = await cmp.buildScatter(
        { ...searchPopulationParams, ...assessmentParams1, property: 'student_scatter', chart_type: 'SCATTER', },
        { ...searchPopulationParams, ...assessmentParams2, property: 'student_scatter', chart_type: 'SCATTER', }
    )

    if (!dataset1 || !dataset2) {
        return console.warn('Unable to load one or both dataset.  Expect to see a message indicating one or both sets did not have data.')
    }

    // Build scatter plot data
    let students = cmp
        .$_.uniqBy(dataset1.concat(dataset2), 'student_id')
        .map(s => ({
            student_id: s.student_id,
            student_full_name: s.student_full_name,
            data: []
        }))

    students.forEach(s => {
        let first = dataset1.find(r => r.student_id === s.student_id)
        let second = dataset2.find(r => r.student_id === s.student_id)

        if (first && second) {
            first.score = parseFloat(first.score)
            second.score = parseFloat(second.score)
            s.data.push(first, second)
        }
    })

    let windowUUID = UUIDv4()
    let windowId = cmp.$dockableWindow({
        name: ['Scatter Plot', savedSearch?.name || state?.savedSearchName].filter(s => !!s).join(' - '),
        uuid: windowUUID,
        component: 'save-search-container',
        parentStyle: {
            overflow: 'hidden'
        },
        events: {
            deletedSavedSearch(id) {
                cmp.queries = cmp.queries.filter(itm => itm.id !== id)
            },
            close() {
                cmp.$store.commit('global/removeDockedWindow', windowId)
            },
        },
        attrs: {
            windowUUID: windowUUID,
            savedSearchParams: arr,
            savedSearchParamsByDomain: savedSearchParamsByDomain,
            component: 'scatter-plot',
            savedSearchType: 'SCATTER',
            configJSON: savedSearch?.config_json || config_json, // config_json for brand-new longitudinal is provided via opts because it must contain summaries
            isSavedSearch: !!state?.savedSearchId,
            searchId: state?.savedSearchId,
            searchName: state?.savedSearchName,
            isDirty: !!state?.isDirty,
            attrs: {
                items: students,
                xPlotLine: cmp.plotConfig(assessmentParams1),
                yPlotLine: cmp.plotConfig(assessmentParams2),
                class: 'pa-5'
            },
            uiConfig: { scope: 'scatter' },
        }
    })
}

export async function doLongitudinalGraph(cmp, opts, scope, savedSearch, state) {
    let arr
    let config_json

    if (!savedSearch) {
        // Unlike all other analytics options (at time of writing), even the
        // brand new longitudinal one sends in pre-build saved search groups
        arr = opts.savedSearchParamsArray
        config_json = opts.configJSON
    } else {
        arr = opts
    }

    // Pre-fetch data for all datasets
    let collatedSavedSearches = cmp.$collateSavedSearchesBySavedSearchId(arr)

    // Longitudinals (typically) have multiple data sets which
    // must be collectively pre-fetched and coalesced into a
    // single chart data object
    let promises = collatedSavedSearches.map((arr, index) => {
        let searchParams = cmp.$_.merge.apply(null, cmp.$_.cloneDeep(arr)) // combine all saved searches in this collation into a single object
        delete searchParams.group_by

        return cmp.fetchDataSetResults(searchParams.data_set_name || `Data Set ${index + 1}`, {
            ...searchParams
        })
    })

    await Promise.all(promises).then(done => {
        cmp.resetResponseData()

        done.forEach((d,i) => {
            if (d.data.scores.length > 0) {
                cmp.parseResponseData(d.data.scores[0].fields[1], d, i)
            }
        })
    })

    let chartConfig = cmp.getChartConfig({
        chart: {
            height: null // override default of (~browser window height) to prevent screen runoff within fe-window
        }
    })
    
    if (chartConfig?.colorAxis) {
        chartConfig.colorAxis.showInLegend = false
    }

    let windowUUID = UUIDv4()
    let windowId = cmp.$dockableWindow({
        name: ['Longitudinal Chart', savedSearch?.name || state?.savedSearchName].filter(s => !!s).join(' - '),
        uuid: windowUUID,
        component: 'save-search-container',
        parentStyle: {
            overflow: 'hidden'
        },
        events: {
            deletedSavedSearch(id) {
                cmp.queries = cmp.queries.filter(itm => itm.id !== id)
            },
            close() {
                cmp.$store.commit('global/removeDockedWindow', windowId)
            },
        },
        attrs: {
            windowUUID: windowUUID,
            savedSearchParams: arr,
            savedSearchParamsByDomain: collatedSavedSearches,
            component: 'highcharts',
            configJSON: savedSearch?.config_json || config_json, // config_json for brand-new longitudinal is provided via opts because it must contain dataSetSummaries
            isSavedSearch: !!state?.savedSearchId,
            searchId: state?.savedSearchId,
            searchName: state?.savedSearchName,
            isDirty: !!state?.isDirty,
            attrs: {
                options: chartConfig, 
                scope: 'district',
            },
        }
    })
}
