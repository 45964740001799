<template>
    <fe-card
        class="alt-card mx-2"
        :style='{height: cardHeight, display: "flex", flexDirection: "column"}'
    >
        <template v-slot:title-text style="text-align: center;">
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px !important; margin-bottom: 10px">
                {{ widgetTitle }}
            </span>
            <fe-info-tip
                color="#7e8494! important"
                icon="fal fa-info-circle"
                class="expore-widget-tooltip"
                style="float: right"
                :tooltip="toolTip"
            >
            </fe-info-tip>
        </template>

        <template v-slot:title-toolbar>
            <div style="margin-bottom: 7px;">
                <v-menu offset-y left z-index="2000">
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="showMenu" text icon :data-test="dataTestBaseString + '-menu-button'">
                            <v-icon v-on="on">more_vert</v-icon>
                        </v-btn>
                    </template>

                    <slot name="menu">
                        <v-list>
                            <slot name="extra-menu-items"></slot>
                            <v-list-item @click="printChart" :data-test="dataTestBaseString + '-print-button'">
                                <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                                Print PDF
                            </v-list-item>
                            <v-list-item @click="exportChart" :data-test="dataTestBaseString + '-download-button'">
                                <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                                Download Image
                            </v-list-item>
                            <v-list-item v-if="showColorBlindMode"
                                @click="colorBlindMode = !colorBlindMode; $emit('colorBlindClick', colorBlindMode)" 
                                :data-test="dataTestBaseString + '-color-blind-button'"
                            >
                                <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-adjust</v-icon>
                                Turn {{ colorBlindMode ? 'off' : 'on' }} Color-Blind Mode
                            </v-list-item>
                        </v-list>
                    </slot>
                </v-menu>
            </div>
        </template>

        <template v-slot:body>
            <div class="explore-body-container">
                <div class="explore-body" :style="countCard && countCard.show ? 'align-content: center' : ''">
                    <div v-if="countCard && countCard.show">
                        <div :ref="'count-card-' + countCard.title" class="count-card-body-container">
                            <div id="count-card-data-viz" class="center-count-card-data">
                                <div v-if="!countCard.error && !countCard.unavailable" class="count-card-percentage">
                                    {{ countCard.count }}<span v-if="countCard.percentage">%</span>
                                </div>
                                <div v-if="countCard.error || countCard.unavailable" class="count-card-no-data">
                                    <v-icon size="55">fal fa-horizontal-rule</v-icon>
                                </div>
                                <div class="count-card-text">
                                    {{ countCard.text }}
                                </div>
                                <div v-if="countCard.change !== 0 && !countCard.error && !countCard.unavailable" id="count-card-change-container">
                                    <div id="count-card-percent-change">
                                        <v-icon class="count-card-change" size="16">{{ `fa-arrow-${countCard.displayUpDownArrow}` }}</v-icon>
                                        {{ countCard.displayChange || countCard.change }}<span v-if="countCard.percentage">% pts </span>
                                    </div>
                                    <div id="count-card-percent-change-text">
                                        from this time last year
                                    </div>
                                </div>
                                <div v-if="countCard.change === 0 && !countCard.error && !countCard.unavailable" id="count-card-change-container">
                                    <div id="count-card-percent-change-text">
                                        No change since this time last year
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <slot name="widget-body" />
                </div>
                <div class="explore-footer" v-if="showFooter">
                    <v-btn v-if="barChartBtn" 
                        @click="$emit('barChartClick'); activeBtn = 'barChart'" text
                        :data-test="dataTestBaseString + '-explore-bar-chart-btn'"
                        :class="{ 'explore-footer-btn-active': activeBtn === 'barChart' }"
                        class="explore-footer-icon-btn"
                    >
                        <v-icon style="color:#495B8F" class="explore-footer-btn-icon">fal fa-chart-bar</v-icon>
                    </v-btn>
                    <v-btn v-if="countBtn" 
                        @click="$emit('countClick'); activeBtn = 'count'" text
                        :data-test="dataTestBaseString + '-explore-count-btn'"
                        :class="{ 'explore-footer-btn-active': activeBtn === 'count' }"
                        class="explore-footer-icon-btn"
                    >
                        <v-icon style="color:#495B8F" class="explore-footer-btn-icon">fal fa-hashtag</v-icon>
                    </v-btn>
                    <v-btn v-if="heatmapBtn" 
                        @click="$emit('heatmapClick'); activeBtn = 'heatmap'" text
                        :data-test="dataTestBaseString + '-explore-heatmap-btn'"
                        :class="{ 'explore-footer-btn-active': activeBtn === 'heatmap' }"
                        class="explore-footer-icon-btn"
                    >
                        <v-icon style="color:#495B8F" class="explore-footer-btn-icon">fal fa-calendar-alt</v-icon>
                    </v-btn>
                    <v-btn v-if="trendBtn" 
                        @click="$emit('trendClick'); activeBtn = 'trend'" text
                        :data-test="dataTestBaseString + '-explore-trend-btn'"
                        :class="{ 'explore-footer-btn-active': activeBtn === 'trend' }"
                        class="explore-footer-icon-btn"
                    >
                        <v-icon style="color:#495B8F" class="explore-footer-btn-icon">fal fa-chart-line</v-icon>
                    </v-btn>
                    <v-btn v-if="rangeBtn" 
                        @click="$emit('rangeClick'); activeBtn = 'range'" text
                        :data-test="dataTestBaseString + '-explore-range-btn'"
                        :class="{ 'explore-footer-btn-active': activeBtn === 'range' }"
                        class="explore-footer-icon-btn"
                    >
                        <v-icon style="color:#495B8F" class="explore-footer-btn-icon">fal fa-tasks-alt</v-icon>
                    </v-btn>
                    <fe-btn
                        @click="$emit('exploreClick')"
                        class="explore-button"
                        usage="secondary"
                        :data-test="dataTestBaseString + '-explore-btn'"
                    >
                    Explore
                    </fe-btn>
                </div>
            </div>
        </template>
    </fe-card>
</template>

<script>
import html2canvas from 'html2canvas'
import Printd from 'printd'
export default {
    name: 'ExploreWidget',

    props: {
        widgetTitle: {
            type: String,
            required: true
        },
        toolTip: {
            type: String,
            required: true
        },
        dataTestBaseString: {
            type: String,
            required: true
        },
        cardHeight: {
            type: String,
            default: '500px'
        },
        barChartBtn: {
            type: Boolean,
            default: false
        },
        countBtn: {
            type: Boolean,
            default: false
        },
        heatmapBtn: {
            type: Boolean,
            default: false
        },
        rangeBtn: {
            type: Boolean,
            default: false
        },
        countCard: {
            type: Object,
            default: () => {}
        },
        trendBtn: {
            type: Boolean,
            default: false
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        showMenu: {
            type: Boolean,
            default: true
        },
        showColorBlindMode: {
            type: Boolean, 
            default: false
        },
    },

    data() {
        return {
            activeBtn: this.barChartBtn ? 'barChart' : null,
            colorBlindMode: false,
        }
    },

    methods: {
        printChart() {
            if(this.countCard?.show) {
                let el = this.$refs['count-card-' + this.countCard.title]
                html2canvas(el, {width: 680, height: 500, x: -130, y: -60})
                    .then(canvas => {
                        const imgURL = canvas.toDataURL('image/jpeg')
                        const image = document.createElement('img')
                        image.src = imgURL
                        image.setAttribute("id", 'print-content')
                        document.body.appendChild(image)

                        const printD = new Printd();
                        printD.print(document.getElementById('print-content'))
                        document.body.removeChild(image)
                    })
            } else {
                this.$emit('printChart')
            }
        },
        exportChart() {
            if(this.countCard?.show) {
                let el = this.$refs['count-card-' + this.countCard.title]
                el.classList.add('explore-widget-download-padding-count-tile')
                html2canvas(el)
                    .then(canvas => {
                        const link = document.createElement('a')
                        link.setAttribute('download', this.countCard.title + '.png')
                        link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
                        link.click()
                    })
                setTimeout(function() { 
                    el.classList.remove('explore-widget-download-padding-count-tile')
                }, 20)
            } else {
                this.$emit('exportChart')
            }
        }
    }
}
</script>

<style scoped lang="scss">

.expore-widget-tooltip {
    position: relative;
    right: 12px;
    top: 5px;
}

.explore-body-container {
    flex-grow: 1;
    height: 100%;
}

.explore-button {
    position: absolute;
    right: 18px;
}

.explore-body {
    height: 85%;
}

.explore-footer-btn-active {
    background-color: #006C90;
    ::v-deep i {
        color: #fff !important;
    }
}

.explore-footer-icon-btn {
    min-width: 36px !important;
    border-radius: 50% !important;
    width: 36px;
    margin: 8px 4px !important;
    ::v-deep i {
        font-size: 1.5rem !important;
    }
}

.explore-footer-icon-btn:not(.explore-footer-btn-active):focus {
    ::v-deep i {
        color: #495B8F !important;
    }
}

.explore-footer-icon-btn:focus {
    outline: 2px solid #2B87FF;
    outline-offset: 2px;
}

.explore-footer-icon-btn:focus::before {
    background-color: #fff;
    opacity: 0;
}

.explore-footer-icon-btn:not(.explore-footer-btn-active):hover {
    ::v-deep i {
        color: #050F2D !important;
    }
}

.count-card-body-container {
    display: grid;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-evenly;

    .center-count-card-data {
        text-align: center;

        .count-card-percentage {
            margin: 5px;
            font-size: 64px;
            font-weight: 700;
        }

        .count-card-no-data {
            color: #495b8f;
        }

        .count-card-text {
            margin: 5px;
            padding-top: 24px;
            font-size: 16px;
            color: #495b8f;
        }

        .count-card-change {
            color: #495b8f !important;
        }

        #count-card-change-container {
            margin-left: auto;
            margin-right: auto;
            max-width:fit-content;
            font-size: 16px;
            color: #495b8f;
        }

        #count-card-percent-change {
            float: left;
            padding-right: 4px;
        }

        #count-card-percent-change-text {
            float: right;
        }
    }
}
</style>

<style lang="scss">
.explore-widget-download-padding-count-tile {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 10px;
}
</style>
