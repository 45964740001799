const greyLinedBar = {
    pattern: {
        path: {
            d: 'M 10 0 L 0 10 M 11 9 L 9 11 M 1 -1 L -1 1',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#8e94a5',
        backgroundColor: '#495B8F'
    }
}

const greenLinedBar = {
    pattern: {
        path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#ffffff',
        backgroundColor: '#099290'
    }
}

const crosshair = 'M5 0 L5 10 M0 5 L10 5'

const purpleShape = {
    pattern: {
        path: {
            d: crosshair,
            strokeWidth: 1,
        },
        width: 5,
        height: 5,
        backgroundColor: '#8E88EA',
        color: '#2B3963'
    }
}

const solidDarkBlue = '#0028a1'

const solidLightBlue = '#2b87ff'

const yellowSquaredBar = {
    pattern: {
        path: {
            d: 'M0,0 L2,0 L2,2 L0,2 Z'
        } ,
        width: 10,
        height: 10,
        color: 'rgba(0, 0, 0, 0.4)',
        backgroundColor: '#FFE072'
    }
}

const lighGreenLinedBar = {
    pattern: {
        path: {
            d: 'M 10 0 L 0 10 M 11 -1 L 9 1 M 1 9 L -1 11',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#ffffff',
        backgroundColor: '#0EA449'
    }
}

const solidRed = '#D71608'

const orangeLinedBar = {
    pattern: {
        path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#050f2d',
        backgroundColor: '#FFA200'
    }
}

export const colorBlindGreyLinedBar = {
    pattern: {
        path: {
            d: 'M 10 0 L 0 10 M 11 -1 L 9 1 M 1 9 L -1 11',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#050f2d',
        backgroundColor: '#ECEDF1'
    }
}

export const singleSeriesPattern = [solidLightBlue]
export const twoSeriesPatterns = [greyLinedBar, solidLightBlue]
export const fiveSeriesPatterns = [greenLinedBar, purpleShape, solidDarkBlue, greyLinedBar, solidLightBlue]
export const incidentRangePatterns = [lighGreenLinedBar, yellowSquaredBar, orangeLinedBar, solidRed]
