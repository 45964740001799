<template>
    <div class="flex-fill flex-column no-scroll" style="height: 400px;">
        <fe-crud-grid
            ref="grid"
            :autoload="true"
            :columnDefs="columnDefs"
            :config="$models.studentIncidents"
            :extraTools="this.hasSecurity"
            :showAddRowBtn="false"
            :showEditbar="this.hasSecurity"
            :readParams="{ student_id: this.updateStudent.student_id, show_all: 1 }"
            @beginCreate="showIncidentCreate=true"
            @read="v => incidents = v"
            @selectionChanged="setSelectedRows"
            @rowDoubleClicked="loadIncident(...arguments)"
            addRowBtnText="Create Incident"
            disableInlineCreate
            displayDensity="medium"
            refreshOnCreated
            refreshOnUpdated
            style="height:100%;"
        >
            <template v-slot:extra-tools>
                <fe-btn
                    useIcon="fa-exchange-alt"
                    usage="ghost"
                    whiteText
                    class="fe-grid-action-btn"
                    @click="$emit('reassignBtnClick', $refs.grid.$refs.crud)"
                >
                    Reassign
                </fe-btn>
            </template>
        </fe-crud-grid>

        <fe-window v-if="showIncidentEdit" @close="incidentUpdate('showIncidentEdit')" title="Edit Incident" height="95%" width="95%">
            <i-frame-renderer app="incident_creation" :cfg="editIncidentCfg" />
        </fe-window>

        <fe-window v-if="showIncidentCreate" @close="incidentUpdate('showIncidentCreate')" title="Create Incident" height="95%" width="95%">
            <i-frame-renderer app="incident_creation" :cfg="urlParams" />
        </fe-window>
    </div>
</template>

<script>
    import Vuex, { mapState } from 'vuex'
    import { mapLocalState } from '@/util/vuexHelper'
    import EditActionItem from '@/components/modules/actionItems/EditActionItem'
    import IFrameRenderer from '@/components/common/IFrameRenderer'

    export default {
        name: 'StudentIncidents',

        components: {
            IFrameRenderer,
            EditActionItem
        },

        data() {
            return {
                incidents: [],
                showAddIncidentBtn: false,
                gridApi: null,
                showIncidentEdit: false,
                showIncidentCreate: false,
                incidentId: null,
                editIncidentCfg: {}
            }
        },

        inject: ['localStore'],

        computed: {
            ...mapState('global', ['sessionUser']),
            ...mapLocalState(['updateStudent','selections','showReassignModal','hasSecurity']),

            urlParams() {
                return {
                    params: {user_id: this.sessionUser.user.id },
                    identifier: new Date().getMilliseconds()
                }
            },

            columnDefs() {
                let me = this

                return [
                    {
                        headerName: "Internal ID",
                        field: "student_id",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Incident Date",
                        valueGetter: params => {
                            return params.data.incident_datetime ? params.data.incident_datetime.split(" ")[0] : params.data.incident_datetime
                        },
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "School Year",
                        field: "school_year_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Type",
                        field: "incident_behavior_display_name",
                        sortable: true,
                        editable: false
                    }, {
                        headerName: "Location",
                        field: "incident_location_name",
                        sortable: true,
                        editable: false
                    }
                ]
            }
        },

        methods: {
            setSelectedRows(meta) {
                this.$data.gridApi = meta.api
                this.selections = meta.api.getSelectedRows()
            },

            loadIncident(meta) {
                this.editIncidentCfg = {
                    params: {
                        user_id: this.sessionUser.user.id,
                        incident_id: meta.data.incident_id
                    },
                    identifier: new Date().getMilliseconds()
                }
                this.showIncidentEdit = true
            },

            incidentUpdate(show) {
                this[show] = false
                this.$refs.grid.$refs.crud.read()
            }
        },
        
        mounted() {
            this.$refs.grid.$refs.grid.gridOptions.deltaRowDataMode = true
            this.$refs.grid.$refs.grid.gridOptions.getRowNodeId = v => {
                return v.student_id + '-' + v.incident_id + '-' + v.id + '-' + v.incident_response_id + '-' + v.incident_behavior_name
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
